<template>
  <div :class="b()">
    <!--Результаты поиска-->
    <div class="flex-grow-1">
      <div data-example-id="default-media" v-if="showResult">
        <v-divider />
        <div class="overline mb-4">
          Найдено результатов {{ objPagination.result_count }}:
        </div>

        <template>
          <div
            v-for="obj in objResultService"
            :key="obj.clinicDepartmentServiceId"
          >
            <v-card class="media" hover outlined>
              <div class="media-body">
                <v-row>
                  <v-col
                    cols="3"
                    sm="2"
                    class="d-flex justify-center align-center"
                  >
                    <v-avatar class="profile" color="grey" size="64" tile>
                      <v-img
                        v-if="obj.departmentAvatar"
                        :src="apiAddress + obj.departmentAvatar"
                        :alt="obj.serviceName + ', ' + obj.departmentName + ', ' + 'Волгоград'"
                      ></v-img>
                      <v-img v-else src="~images/base_img_doctor.webp"></v-img>
                    </v-avatar>
                    <div>
                      <div class="display-m-none">
                        <!-- <rating-component :rating="data.userRating" :category = "data.doctorCategory"
                                                                                                            :degree="data.academicDegree"> </rating-component>-->
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="9" sm="5">
                    <h5
                      class="media-heading"
                      itemprop="description"
                      @click="
                        openDrawerFast(
                          obj.clinicDepartmentServiceId,
                          obj.serviceName,
                          obj.departmentName,
                          obj.departmentId,
                          obj.servicePrice,
                          obj.cityPart,
                          obj.departmentAddress
                        )
                      "
                    >
                      {{ obj.serviceName }}
                    </h5>
                    <div class="d-none d-sm-block d-md-block">
                      <template v-if="obj.departmentName">
                        <router-link
                          :to="{
                            path: '/department/' + obj.departmentUrl + '/' +  obj.departmentId,
                            params: { depId: obj.departmentId, depName: obj.departmentUrl },
                          }"
                            target="_blank"
                        >
                          <span :class="b('department')"
                            >{{ obj.departmentName }}, {{ obj.cityPart }}</span
                          >
                          <br />
                        </router-link>
                      </template>
                      <template v-if="obj.distance">
                        <span :class="b('distance')">
                          До этого врача {{
                            Math.floor(obj.distance.kilometers * 100) / 100
                          }} километра
                        </span>
                      </template>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    class="d-flex align-center justify-center"
                  >
                    <span> Цена за услугу: {{ obj.servicePrice }} </span>
                  </v-col>
                  <v-col cols="12" class="d-block d-sm-none d-md-none">
                    <template v-if="obj.departmentName">
                      <router-link
                        :to="{
                            path: '/department/' + obj.departmentUrl + '/' +  obj.departmentId,
                            params: { depId: obj.departmentId, depName: obj.departmentUrl },
                        }"
                        target="_blank"
                      >
                        <span :class="b('department')"
                          >{{ obj.departmentName }}, {{ obj.cityPart }}</span
                        >
                        <br />
                      </router-link>
                    </template>
                    <template v-if="obj.distance">
                      <span :class="b('distance')">
                        До этого врача {{
                          Math.floor(obj.distance.kilometers * 100) / 100
                        }} километра
                      </span>
                    </template>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    class="text-center btn-container d-flex flex-row flex-sm-column align-center justify-center"
                  >
                    <v-btn
                      outlined
                      class="btn-detail mr-2 mb-0 mb-sm-2 mr-sm-0"
                      color="primary"
                      @click="
                        openDrawerDetail(
                          obj.clinicDepartmentServiceId,
                          obj.serviceName,
                          obj.departmentName,
                          obj.departmentId,
                          obj.servicePrice,
                          obj.cityPart,
                          obj.departmentAddress
                        )
                      "
                    >
                      Подробнее
                    </v-btn>

                    <v-btn
                      outlined
                      class="btn-order"
                      color="success"
                      @click="
                        openDrawerFast(
                          obj.clinicDepartmentServiceId,
                          obj.serviceName,
                          obj.departmentName,
                          obj.departmentId,
                          obj.servicePrice,
                          obj.cityPart,
                          obj.departmentAddress
                        )
                      "
                    >
                      Записаться
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
        </template>
        <div class="flex-shrink-0">
          <div
            class="pagination text-center pb-4 pt-3"
            v-show="objPagination.actual_count >= 2 && showResult"
          >
            <v-pagination
                    v-model="page"
                    :length="objPagination.actual_count"
                    :prev-icon="icons.mdiMenuLeft"
                    :next-icon="icons.mdiMenuRight"
                    @input="searchEntity"
            >
            </v-pagination>
          </div>
        </div>
      </div>
      <!--Если результатов поиска не найдено-->
      <div v-show="showNotResult">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div v-else>
          <not-found />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import NotFound from "./MainResultNotFound";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";

const COMPONENT_NAME = "MainResultService";

export default {
  name: COMPONENT_NAME,
  props: ["data"],
  components: { NotFound },
  created() {
    this.searchEntity(false);
  },
  data: () => ({
    loading: false,
    page: 1,
    icons: {
      mdiMenuRight,
      mdiMenuLeft,
    },
  }),
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 3000);
  },
  watch: {
    selectedSortValue() {
      this.searchEntity(true);
    },
    selectedTypeValue() {
      this.searchEntity(true);
    },
    selectedPrice() {
      this.searchEntity(true);
    },
    selectedCityParts() {
      this.searchEntity(true);
    },
    selectedMyCoordinatesLong() {
      this.searchEntity(true);
    },
    selectedMyCoordinatesLat() {
      this.searchEntity(true);
    },
  },
  computed: {
    ...mapGetters({
      selectedSortValue: names.SELECTED_SORT_VALUE,
      selectedTypeValue: names.SELECTED_TYPE_VALUE,
      selectedCityParts: names.SELECTED_CITY_PARTS,
      selectedPrice: names.SELECTED_PRICE_VALUE,
      selectedMyCoordinatesLat: names.SELECTED_MY_COORDINATES_LAT,
      selectedMyCoordinatesLong: names.SELECTED_MY_COORDINATES_LONG,
      objResultService: names.OBJ_SEARCH_SERVICE_BY,
      objPagination: names.OBJ_SEARCH_PAGINATION,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),
    apiAddress() {
      return names.API_ADDRESS;
    },

    showResult() {
      return !isEmpty(this.objResultService);
    },
    showNotResult() {
      return this.data.entityName !== null && isEmpty(this.objResultService);
    },
  },
  methods: {
    ...mapActions({
      mainResultService: names.ACTION_MAIN_SEARCH_SERVICE,
      changeEntityId: names.ACTION_TRANSMISSION_ENTITY_ID,
      selectedServiceInfo: names.ACTION_SAVE_SELECTED_SERVICE_INFO,
      clearResult: names.ACTION_CLEAR_OBJ_SERVICE_BY,
    }),
    async searchEntity(update) {
      if (update === false) {
        this.clearResult();
      }
      this.mainResultService({
        serviceId: this.data.entityId,
        cityId: this.selectedCity.id,
        sort: this.selectedSortValue,
        filterChildren: this.selectedTypeValue,
        filterPriceStart: this.selectedPrice[0],
        filterPriceEnd: this.selectedPrice[1],
        cityPart: this.selectedCityParts,
        myCoordinatesLat: this.selectedMyCoordinatesLat,
        myCoordinatesLong: this.selectedMyCoordinatesLong,
        update: update,
        page: this.page,
      });
    },
    openDrawerDetail(
      entityId,
      serviceName,
      departmentName,
      departmentId,
      servicePrice,
      cityPart,
      departmentAddress
    ) {
      this.changeEntityId(entityId);
      this.selectedServiceInfo({
        serviceId: entityId,
        serviceName: serviceName,
        departmentName: departmentName,
        departmentId: departmentId,
        servicePrice: servicePrice,
        cityPart: cityPart,
        departmentAddress: departmentAddress,
      });
      this.$drawer.showDrawer(true, "Detail");
      document.querySelector("body").style.overflow = "hidden";
    },

    openDrawerFast(
      entityId,
      serviceName,
      departmentName,
      departmentId,
      servicePrice,
      cityPart,
      departmentAddress
    ) {
      this.selectedServiceInfo({
        serviceId: entityId,
        serviceName: serviceName,
        departmentName: departmentName,
        departmentId: departmentId,
        servicePrice: servicePrice,
        doctorName: null,
        doctorId: null,
        cityPart: cityPart,
        departmentAddress,
      });
      document.querySelector("body").style.overflow = "hidden";
      this.changeEntityId(entityId);
      this.$drawer.showDrawer(true, "Fast");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.MainResultService {
  display: flex;
  flex-direction: column;
}

span {
  color: #000;
  font-family: $sanspro;
  font-size: 14px;
}

.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 10px;
  border: 1px solid #f2f2f2;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }

  a span{
    text-decoration: none;
    &:hover {
      color: #0044cc;
      text-decoration: underline;
    }
  }

  .media-heading {
    font-size: 20px;
    &:hover {
      color: #0044cc;
    }
    @include smaller-phone {
      padding-left: 10px;
    }
  }

  .btn-container {
    @include smaller-phone {
      margin-top: 20px;
    }

    button {
      width: 120px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
